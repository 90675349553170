import './App.css';
import banner from './ifleetxBanner.jpg';
import logo from './ifleetxLogo.png';
import insights from './ifleetxInsights.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="iFleetX" style={{width:"100%"}}></img>
        <img src={banner} alt="Pretty Picture" style={{width:"100%"}}></img>
      </header>
      <a className ="App-H1">Blasting Insights Using as Loaded Data</a>
      <img src={insights} alt="Insights" style={{width:"100%"}}></img>
    </div>
  );
}

export default App;
